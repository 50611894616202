* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  /* //disable vertical scroll */
  overflow-x: hidden !important;
}

.wrapper {
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px;
}
.mySlides {
  display: none;
}
.disable-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
